<template>
  <div>
    <div>
      <breadcrumb :breadcrumb_link="eventinquiry_breadcrumb" />
    </div>
    <div>
      <Qnainquiry v-show="stage === 0" @nextPage="nextPage" />
    </div>
    <div>
      <Qnacontact v-show="stage === 1" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Qnainquiry from '@/pages/Footerlink/Qnainquiry';
import Qnacontact from '@/pages/Footerlink/Qnacontact';
export default {
  name: 'QnaMain',
  components: {Breadcrumb, Qnainquiry, Qnacontact},
  data() {
    return {
      eventinquiry_breadcrumb: {
        toplink: 'HOME',
        prevlink: '고객의소리',
        present: '고객의소리',
      },
      stage: 0,
    };
  },
  methods: {
    nextPage() {
      console.log('NEXT PAGE CALL');
      this.stage = 1;
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '고객의소리'});
  },
};
</script>

<style scoped></style>
